// Import mixins
@import 'components/mixins.scss';

.ant-btn {
//   &:hover,
//   &:active,
//   &:focus {
//     border-color: lighten($blue-light, 0%);
//     color: lighten($blue-light, 0%);
//   }
//   &-primary {
//     background: $primary !important;
//     border-color: $primary !important;

//     &:hover,
//     &:active,
//     &:focus {
//       background: lighten($blue-light, 0%) !important;
//       border-color: lighten($blue-light, 0%) !important;
//       color: $white;
//     }
//   }
  &-danger {
    &:hover,
    &:active,
    &:focus {
      background: lighten($danger, 5%);
      border-color: lighten($danger, 5%);
      color: $white;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-btn {
    &-primary {
      &:hover,
      &:active,
      &:focus {
        background: lighten($blue-light, 0%) !important;
        border-color: lighten($blue-light, 0%) !important;
        color: $white;
      }
    }
    &-danger {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 10%);
        border-color: lighten($danger, 10%);
        color: $white;
      }
    }
  }
}
